(function () {
    'use strict';

    /**
     * Base settings
     */
    const
    configStarterBase = {
        'localStorageReferrerName': 'ektracker_referrer',
        'localStorageRG0': 'ektracker_rg0',
        'localStorageRG1': 'ektracker_rg1',
        'localStorageRG2': 'ektracker_rg2',
        'initScriptLocalStorageNameReferrer': 'ecr',
        'initScriptLocalStorageNameFirstUrl': 'ecu'
    };
    // starter script storage
    var starterStorage =
        (function () {
            var add = function(data) {
                localStorage.setItem(configStarterBase.localStorageName, data);
            }
            var get = function() {
                let data = localStorage.getItem(configStarterBase.localStorageName);
                empty();
                return (data === undefined || data === null)?false:data;
            }
            var empty = function() {
                localStorage.removeItem(configStarterBase.localStorageName);
            }
            return {
                add: add,
                get: get,
                empty: empty
            }
        })();
    /**
     * Settings for campaign guide manager
     */
    const
        configCampaignGuide = {
            'transferTable': [
                // from is var. get in URL, to is name for backend
                {
                    'from': 'rg0',
                    'to': 'referrer_guid_0'
                },
                {
                    'from': 'rg1',
                    'to': 'referrer_guid_1'
                },
                {
                    'from': 'rg2',
                    'to': 'referrer_guid_2'
                }
            ]
        };
    // campaign guide manager
    var campaignGuide = (function () {
        var getGUIDFromUrl = function (url) {
            let arrUrl = url.split('?');
            if(arrUrl.length < 2) {
                return false;
            }
            let
                arrListQueryString = arrUrl[1].split('&'),
                out = [];
            for(let x = 0; x < arrListQueryString.length; x++) {
                for(let i = 0; i < configCampaignGuide.transferTable.length; i++) {
                    let from = configCampaignGuide.transferTable[i]['from'];
                    if(arrListQueryString[x].indexOf(from) === 0) {
                        let arrValue = arrListQueryString[x].split('=');
                        if(arrValue.length === 2) {
                            out.push({
                                'name': configCampaignGuide.transferTable[i]['to'],
                                'value': arrValue[1]
                            });
                        }
                    }
                }
            }
            return (out.length > 0?out:false);
        }
        var getGUIDFromLocalStorage = function () {
            let out = [];
            for(let i=0; i<configCampaignGuide.transferTable.length; i++) {
                let localStorageKey = configCampaignGuide.transferTable[i]['to'],
                    guid = localStorage.getItem(localStorageKey);
                if(guid !== undefined && guid !== null) {
                    out.push({
                        'name': localStorageKey,
                        'value': guid
                    });
                    localStorage.removeItem(localStorageKey);
                }
            }
            return (out.length > 0?out:false);
        }
        return {
            getGUIDFromUrl: getGUIDFromUrl,
            getGUIDFromLocalStorage: getGUIDFromLocalStorage
        }
    })();
    // referrer manager (history)
    var referrer =
        (function () {
    
            var get = function() {
                // first we try find referrer from starter script,
                // which can not be blocked by cookie bar
                let
                    starterStorageReferrer = starterStorage.get(),
                    url = starterStorageReferrer!==false?starterStorageReferrer:document.referrer;
                if(url.length === 0){
                    return false;
                }
                if(isThisInternalUrl(url)) {
                    return false;
                }
                return url;
            }
            var isThisInternalUrl = function(url) {
                let urlObject = new ParsedUrl(url);
                return urlObject.hostname === location.hostname;
            }
            // help from: https://stackoverflow.com/a/38557322
            function ParsedUrl(url) {
                var parser = document.createElement("a");
                parser.href = url;
    
                // IE 8 and 9 dont load the attributes "protocol" and "host" in case the source URL
                // is just a pathname, that is, "/example" and not "http://domain.com/example".
                parser.href = parser.href;
    
                // IE 7 and 6 wont load "protocol" and "host" even with the above workaround,
                // so we take the protocol/host from window.location and place them manually
                if (parser.host === "") {
                    var newProtocolAndHost = window.location.protocol + "//" + window.location.host;
                    if (url.charAt(1) === "/") {
                        parser.href = newProtocolAndHost + url;
                    } else {
                        // the regex gets everything up to the last "/"
                        // /path/takesEverythingUpToAndIncludingTheLastForwardSlash/thisIsIgnored
                        // "/" is inserted before because IE takes it of from pathname
                        var currentFolder = ("/"+parser.pathname).match(/.*\//)[0];
                        parser.href = newProtocolAndHost + currentFolder + url;
                    }
                }
    
                // copies all the properties to this object
                var properties = ['host', 'hostname', 'hash', 'href', 'port', 'protocol', 'search'];
                for (var i = 0, n = properties.length; i < n; i++) {
                    this[properties[i]] = parser[properties[i]];
                }
    
                // pathname is special because IE takes the "/" of the starting of pathname
                this.pathname = (parser.pathname.charAt(0) !== "/" ? "/" : "") + parser.pathname;
            }
            return {
                get: get,
                isThisInternalUrl: isThisInternalUrl
            }
        })();

    let referrerUrl = localStorage.getItem(configStarterBase.initScriptLocalStorageNameReferrer);
    if(referrerUrl === undefined || referrerUrl === null) {
        // nothing here
    } else {
        if(referrer.isThisInternalUrl(referrerUrl) === false) {
            starterStorage.add(referrerUrl)
        } else {
            localStorage.removeItem(configStarterBase.initScriptLocalStorageName);
        }
    }

    let firstUrl = localStorage.getItem(configStarterBase.initScriptLocalStorageNameFirstUrl);
    if(firstUrl === undefined || firstUrl === null) {
        // nothing here
    } else {
        let arrOfGUID = campaignGuide.getGUIDFromUrl(firstUrl);
        if(arrOfGUID !== false) {
            for(let i=0; i<arrOfGUID.length; i++) {
                localStorage.setItem(arrOfGUID[i]['name'], arrOfGUID[i]['value']);
            }
        }
    }


})(window);